import { createRouter, createWebHashHistory } from 'vue-router'
import title1 from '@/lang/en'
import title2 from '@/lang/zh'

import Home from '@/views/Home/home'
import auto from '@/views/auto/auto'
import ContactUs from '@/views/ContactUs/ContactUs'
import Gamelist from '@/views/Game/Gamelist'
import Gameinside from '@/views/Game/Gameinside'
import Secret from '@/views/Secret/secret'
import Terms from '@/views/Terms/terms'


let title = {}
if(sessionStorage.getItem('lang')==='zh_cn'){
    title = title2.title
}else{
    title=title1.title
}
//路由数组
const routes = [
    {
        //基本格式
        path: "/",
        name: "Home",
        component: Home,
        meta:{
            title:'首页'
        }
    },
    {
        //基本格式
        path: "/auto",
        name: "Auto",
        component: auto,
        meta:{
            title:'关于我们'
        }
    },
    {
        //基本格式
        path: "/ContactUs",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        //基本格式
        path: "/Gamelist",
        name: "Gamelist",
        component: Gamelist,
    },
    {
        //基本格式
        path: "/Gameinside",
        name: "Gameinside",
        component: Gameinside,
    },
    {
        //基本格式
        path: "/policy",
        name: "policy",
        component: Secret,
    },
    {
        path:"/terms",
        name: "terms",
        component: Terms,
    }
]
//路由对象
const router = createRouter({
    mode: 'hash',
    history: createWebHashHistory(),
    routes //上面的路由数组
})

// 设置关键字等
let head = document.getElementsByTagName('head');
let meta = document.createElement('meta');
document.querySelector('meta[name="keywords"]').setAttribute('content', title.Keywords)
document.querySelector('meta[name="description"]').setAttribute('content',title.Description)
head[0].appendChild(meta)
document.title = title.title;

//导出路由对象，在main.js中引用
export default router
