import { createI18n } from 'vue-i18n'
import el_zh_cn from 'element-plus/lib/locale/lang/zh-cn'
import el_en from 'element-plus/lib/locale/lang/en'

import zh_cn from './zh'
import en from './en'

const language = (
    (navigator.language ? navigator.language : navigator.userLanguage) || "zh_cn"
).toLowerCase();
const messages = {
    'zh-cn': {
        el: el_zh_cn,
        ...zh_cn
    },
    'en': {
        el: el_en,
        ...en
    }
}

const i18n = createI18n({
    legacy: false,
    locale: language.split("-")[0] || "zh_cn",
    fallbackLocale: 'zh-cn',
    globalInjection: true,
    messages,
})

export default i18n;
