export default {
    Nav: {
        Home: '官网首页',
        About: '关于我们',
        Game: '游戏产品',
        Joinus: '加入我们',
        contactUs: '联系我们',
        secret:'隐私协议',
        terms:'服务条款',
    },
    Footer: {
        cont: "Copyright {'@'} 2023 All Right Reserved 欣盛網絡有限公司 版权所有",
    },
    commencont:{
        Coming: '敬请期待'
    },
    homecont: {

    },
    contactus: {
        address:'中国香港九龙尖沙咀柯士甸路7-9號煥利商業大厦7樓63室',
        title:'欣盛網絡有限公司'
    },
    Aboutus:{
        about1: '欣盛網絡有限公司成立于2022年5月，总部位于中国香港，是一家以手机游戏为核心，集研发、运营、发行为一体的综合性互动娱乐企业。',
        about2: '公司核心团队成员来自于全球知名游戏企业，拥有丰富的游戏研发和运营经验，一直致力于以自主创新为基石，凭借雄厚的技术实力和创意游戏设计能力，多款卡牌、策略、MMORPG、休闲游戏正在紧锣密鼓研发中，即将发行中国港澳台地区、日本、韩国、欧州、中东、东南亚等全球市场。',
        about3: '未来，欣盛游戏将继续开发和发布精品游戏产品，超越用户的期望，为全球各地的玩家带来有趣、令人兴奋的娱乐体验和优质服务。',
    },
    title:{
        title: '欣盛游戏 - 全球手机游戏发行与研发商',
        Keywords: '欣盛游戏,手机游戏,網絡游戏,mobile game,安卓游戏,苹果游戏,ios游戏,android游戏,网络游戏,发行商,海外游戏,香港游戏,谷歌商店,苹果商店,下载,安装,免费游戏.',
        Description:'一家手机游戏研发与发行商。'
    },
    Secret:{
        src:'/static/policy.html'
    },
    Terms:{
        src:'/static/terms.html'
    }
}
